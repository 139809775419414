import restaurant from "./images/bahmut.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/6.png";
import imgheadermb from "./images/masa9.jpg";
import imgheader from "./images/masa9.jpg";
import imgheadermiini from "./images/masa16.jpg";
import imgheadermiinimb from "./images/masa13.jpg";
import logo from './images/masalogo.png'



const data = {
    introData: [{
        copilul: "Teo",
        familia: "fam. Catana",
        logo: logo,
        tata: "Ion",
        mama: "Anastasia",
        data: "19 Iulie 2023",
        data_confirmare: "1 iulie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "nastyca1624@gmail.com", 
       tel: "+37369957491",
       phone: "tel:+37369957491",
       viber: "viber://chat?number=%2B37369957491",
       whatsapp: "https://wa.me/+37369957491",
       messenger: "https://www.messenger.com/t/100005637663495",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Localul",
            name: "Bahmut Club",
            data: "19 iulie 2023, miercuri, ora 17:00",
            adress: "Bahmut, Gara Bahmut, 1, Ungheni 4412",
            harta: "https://goo.gl/maps/gbLLGd61p33AwbTT9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10816.306669607438!2d28.087953!3d47.332374!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1b0995d6e208936e!2sBahmut%20Club!5e0!3m2!1sro!2s!4v1674328674595!5m2!1sro!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Salutare",
          
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title1: "Cu mult drag și încântare ",
            title2: "Te invit la o petrecere tare!",
            title3: "Cu muzică și voie bună",
            message: "Ne vom distra clar de minune!",  
        }
    ],

}

export default data;