import React from "react";
import { ParallaxProvider } from 'react-scroll-parallax';
import BlogSectionNoi from "../blogSectionNoi";
import CardLocal from "../cardLocal";
import '../css/Body.css';
import '../css/Timer.css';
import data from "../Data";
import FormSection from "../FormSection";
import music from "../images/music.png";
import muz from "../music/cumetri.mp3";
import Parallaximg from "../Parallaximg";
import CardInvitatie from "./CardInvitatie";
import Slide from "./Slide";
import Timer from "./Timer";


const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 500 ? item.imgdesktop : item.imgmobil} height="700px" opacity=".8" >
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 pt-5 mt-5" style={{ maxWidth: '600px', padding: '20px' }}>
                                <h1 style={{ height: '95px' }}></h1>
                                <h1 className="animate__animated animate__bounceInLeft mt-5 pt-5" style={{ fontSize: '55px' }}>{item.copilul}</h1>
                                <h4 className="animate__animated animate__bounceInRight animate__delay-2s animate__slower" style={{ fontSize: '36px' }}>{item.savedata}<br />{item.data}</h4>
                                <Timer />
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}

            <div className="text-center mt-4 pt-4">
                <span className="text-center">
                    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music} />
                    <audio src={muz} controls autoPlay loop ></audio>
                </span>
            </div>

            <BlogSectionNoi />

            <Slide />



            {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".8">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 mb-3" style={{ maxWidth: '600px', padding: '20px' }}>
                                <h4 className="text-center">
                                    {item.title1}
                                </h4>
                                <h4 className="text-center p-2">
                                    {item.title2}
                                </h4>
                                <h4 className="text-center p-2">
                                    {item.title3}
                                </h4>
                                <h4 className="text-center pb-5">
                                    {item.message}
                                </h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}


            <CardInvitatie />


            <CardLocal />


            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            {item.familia}
                        </h3>
                    </div>



                )
            })}


            {/** <Alert/>*/}


        </ParallaxProvider>



    )
}

export default Home;