
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import mikey from '../images/masalogo.png'
import "../css/ImgInvitatie.css"


const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text">
                           
                            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                                <br />
                            </h5>
                           

<h4>Teo</h4>
<h5> mă numesc <br/>
Drăgălaș și cumințel, <br/>
Și am ceva să spun: <br/>
    mami și cu tati </h5>
   <h4>Ion și Anastasia</h4> 
   <h5>și alături de nașii</h5>
   <h4>Andrei și Liubovi</h4>
   <h5>Vor petrecere în cinstea mea!</h5>
<h5>Și de vreți să ne vedem<br/>
Semnați în cartea de oaspeți,<br/>
La <b style={{fontSize: '20px', color: 'green', textShadow: '1px 1px 2px grey', }}>CUMETRIE</b>  Vă invităm,<br/>
Cu toții să ne distrăm.
</h5>
<h5>Însă mai am ceva să anunț: <br/>
    Și un <b style={{fontSize: '20px', color: 'green', textShadow: '1px 1px 2px grey', }}>LA MULȚI ANI!</b> 
     <br/> lui <b style={{fontSize: '20px', color: 'green', textShadow: '1px 1px 2px grey', }}>TATI</b> vom cânta că va fi omagiat<br/> 
     și el în acestă zi importantă!
</h5>
                            
                            <img id="mikey_img" src={mikey} />

                            <h4 class="m-2">Miercuri, 19 Iulie 2023</h4>
                        </div>


                       
                            <ImgInvitatie />

                            
                      


   
<h4 class="card-footer">Vă așteptăm cu drag!</h4>

         <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>                
                        
                    </div>




                </div>

                 
   
                
            </div>

            
        </section>

    )
}

export default CardInvitatie;